
<template>
  <div v-if="isShowBank==true">    
    <view class='date-confirm'>
      <view @click='closePop' class="cancel-btn">取消</view>
      <view class="input-box">
        <van-search placeholder="请输入开户银行支行关键字" v-model="queryParams.bankName" @clear="onSearch" @blur="onSearch" />
      </view>
      <view @click="confirmBank" class="sure-btn">确定</view>
    </view>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" class="order-list-refresh">
      <van-list
        class="order-flow"
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="item-list">     
          <van-radio-group v-model="checkBank.id">          
            <div class="label bank-item" v-for="(item,index) in list" :key="index">
              <van-radio :name="item.id" checked-color="#FA3474" class="radio-box">{{item.bankName}}</van-radio>
            </div>  
          </van-radio-group>
        </div>
      </van-list>
    </van-pull-refresh>

  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
import { getBankListApi } from "@/api/apply";
export default {
  name: 'MyPicker',  
  emits: ['confirm', 'hide'],
  props: {
    // 初始化银行id
    initBankId: null,
    // 初始化银行名称
    initBankName: {
      type: String,
      value: ''
    },
    //省市区地址id
    addressStandardId:{
      type: Array,
      value: []
    },
    isShowBank: {
      type: Boolean,
      value: false
    },  
  },
  setup(props,ctx) {
    const state = reactive({
      finished: false,
      refreshing: false,
      totalPage:0,
      list: [],
      loading: false,
      searchVal:'',
      queryParams: {    // 查询参数
        pageNum: 1,
        pageSize: 10,
        bankName:'',  // 用户输入的银行支行关键词
        standardId:null
      },
      checkBank:{   //选中的银行
        bankAliasName: '',
        bankAliasNo: '',
        bankName: '',
        bankNo: '',
        cityCode: '',
        id: '',
        parentBankNo: '',
      },
    }) 
    onMounted(() => {
      if(props.initBankId){
        state.checkBank.id = props.initBankId;
        state.checkBank.bankName = props.initBankName;
      }
      
      // var standardId = props.addressStandardId[2];
      // if(!standardId){
      //   standardId = props.addressStandardId[1].substring(0,4);
      // }else{
      //   standardId = standardId.substring(0,4);
      // }
      // state.queryParams.standardId = standardId;
    })

    //支行列表
    const loadData = () => {        
      getBankListApi(state.queryParams).then(res => {
        if(res && res.rows){
          const data = res.rows;
          state.list = state.list.concat(data)
          state.totalPage = res.pageCount;
          state.loading = false;
          if (state.queryParams.pageNum >= res.pageCount) state.finished = true
        }
      });
    }
    //加载数据
    const onLoad = () => {
      if (!state.refreshing && state.queryParams.pageNum < state.totalPage) {
        state.queryParams.pageNum = state.queryParams.pageNum + 1
      }
      if (state.refreshing) {
        state.list = [];
        state.refreshing = false;
      }
      loadData();
    }
    //下拉刷新
    const onRefresh = () => {
      state.refreshing = true
      state.finished = false
      state.loading = true
      state.queryParams.pageNum = 1
      onLoad();
    }

    //搜索
    const onSearch = () => {
      if(state.searchVal != state.queryParams.bankName){
        state.searchVal = state.queryParams.bankName;
        state.refreshing = true
        state.finished = false
        state.loading = true
        state.list = [];
        state.queryParams.pageNum = 1
        onLoad();
      }
    }

    //选择
    const changeBank = (id) => {
      for(let i=0,len=state.list.length; i<len; i++){
        if(state.list[i].id == id){
          state.checkBank = state.list[i];
          break;
        }
      }
    }

    //确定
    const confirmBank = () => {      
      if(state.checkBank && state.checkBank.id){
        var checkBank = state.checkBank;
        for(let i=0,len=state.list.length; i<len; i++){
          if(state.list[i].id == state.checkBank.id){
            checkBank = state.list[i];
            break;
          }
        }
        // 通过发送自定义事件把用户选择的银行支行传递到父组件
        ctx.emit('confirm',checkBank);   
      }
    }

    //关闭弹窗
    const closePop = () => {
      ctx.emit('hide');      
    };

    return {
      ...toRefs(state),
      onLoad,
      onRefresh,
      onSearch,
      changeBank,
      confirmBank,
      closePop
    }
  }
}
</script>

<style lang='less' scoped>  

.date-confirm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  font-size: 17px;
  height: 60px;
}

.cancel-btn {
  color: #999;
  font-size: 15px;
}

.sure-btn {
  color: #5f80b9;
  font-size: 15px;
}
.input-box {
  width: 70%;
}
.order-list-refresh{
  height: 370px;
  overflow-y: auto;
}
.bank-item{
  height: 50px;
  padding: 0 15px;
  border-bottom: 1px solid #eee;
}
.radio-box{
  width: 100%;
  height: 100%;
  font-size: 13px;
}
</style>
