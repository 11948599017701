// 查询渠道列表
import request from "@/utils/request";

//图片上传
export function uploadFileApi(data) {
  return request({
    url: '/wxapp/oss/upload',
    method: 'post',
    data,
    headers: { 
      "Content-Type": "multipart/form-data"
    }
  })
}

