<template>
  <div class="container">
    <!-- 进度条 -->
    <div class="progress-bar">
      <div class="step-box rowCenter">
        <div class="step-item center" :class="{'curr':step==1,'active':step>1}">
          <div class="step-bar center">
            <div class="circle-box center">
              <div class="circle"></div>
            </div>          
          </div>
          <span class="step-text">基本信息</span>
        </div>
        <div class="step-item center" :class="{'curr':step==2,'active':step>2}" v-if="channelCode != 'ZFB'">
          <div class="step-bar center">
            <div class="step-line"></div>
            <div class="circle-box center">
              <div class="circle"></div>
            </div>          
          </div>
          <span class="step-text">账户结算</span>
        </div>
        <div class="step-item center" :class="{'curr':step==3,'active':step>3}">
          <div class="step-bar center">
            <div class="step-line"></div>
            <div class="circle-box center">
              <div class="circle"></div>
            </div>          
          </div>
          <span class="step-text">门店照片</span>
        </div>
        <div class="step-item center" :class="{'curr':step==4,'active':step>4}">
          <div class="step-bar center">
            <div class="step-line"></div>
            <div class="circle-box center">
              <div class="circle"></div>
            </div>          
          </div>
          <span class="step-text">协议信息</span>
        </div>
      </div>
    </div>
    <!-- 第一步 -->
    <div class="step-container step-one" v-show="step==1">
      <div class="step-title">账户类型</div>
      <div class="step-body">
        <div class="form-row" v-if="channelCode != 'ZFB'">
          <van-radio-group class="radio-group row" v-model="datalist.merType" direction="horizontal" :disabled="payStatus==3">
            <van-radio class="radio row" name="0">个体商户</van-radio>
            <van-radio class="radio row" name="1">企业商户</van-radio>
          </van-radio-group>
        </div>
        <div class="form-row">
          <div class="form-title">营业执照 <em class="required">*</em></div>
          <div class="form-controls rowCenter">
            <div class="camera-area center" :class="{'valid-failed':datalist.businessLicenceNo==null}">
              <div class="camera-box center" v-if="payStatus==3" @click="previewPic(datalist.usccImg)">
                <van-image :src="datalist.usccImg||'https://cdn.teamax-cn.cn/static/Images/pay/material5.png'" class="material-img"	fit="cover" />            
                <van-image :src="cameraPic" class="camera-img"	fit="cover" />  
              </div>
              <van-uploader :after-read="(file) => uploadImg(file, 'usccImg', 'buz')" name="usccImg" v-else>
                <div class="camera-box center">
                  <van-image :src="datalist.usccImg||'https://cdn.teamax-cn.cn/static/Images/pay/material5.png'" class="material-img"	fit="cover" :show-loading="true" />    
                  <div class="img-load-box" v-if="loadField=='usccImg' && showLoad==true">
                    <van-loading color="#fff" vertical size="24">上传中...</van-loading>
                  </div>  
                  <van-image :src="cameraPic" class="camera-img"	fit="cover" />
                </div>
              </van-uploader>
            </div>
            <div class="eg-box center">
              <span class="eg-text">示例</span>           
              <van-image src="https://cdn.teamax-cn.cn/static/Images/pay/material3.png" class="eg-img"	fit="cover" @click="previewPic('https://cdn.teamax-cn.cn/static/Images/pay/material3.png')" />            
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-title">法人身份证 <em class="required">*</em></div>
          <div class="form-controls rowCenter">
            <div class="camera-area center" :class="{'valid-failed':!datalist.cardNo}">
              <div class="camera-box center" v-if="payStatus==3" @click="previewPic(datalist.legalFrontImg)">
                <van-image :src="datalist.legalFrontImg||'https://cdn.teamax-cn.cn/static/Images/pay/material1.png'" class="material-img"	fit="cover" />            
                <van-image :src="cameraPic" class="camera-img"	fit="cover" />
              </div>
              <van-uploader :after-read="(file) => uploadImg(file, 'legalFrontImg', 'face')" name="legalFrontImg" v-else>
                <div class="camera-box center">
                  <van-image :src="datalist.legalFrontImg||'https://cdn.teamax-cn.cn/static/Images/pay/material1.png'" class="material-img"	fit="cover" />            
                  <div class="img-load-box" v-if="loadField=='legalFrontImg' && showLoad==true">
                    <van-loading color="#fff" vertical size="24">上传中...</van-loading>
                  </div>  
                  <van-image :src="cameraPic" class="camera-img"	fit="cover" />
                </div>
              </van-uploader>
              <span class="material-title">身份证头像面</span>
            </div>
            <div class="camera-area center id-reverse" :class="{'valid-failed':!datalist.cardStartDate}">
              <div class="camera-box center" v-if="payStatus==3" @click="previewPic(datalist.legalBackImg)">
                <van-image :src="datalist.legalBackImg||'https://cdn.teamax-cn.cn/static/Images/pay/material2.png'" class="material-img"	fit="cover" />            
                <van-image :src="cameraPic" class="camera-img"	fit="cover" />
              </div>
              <van-uploader :after-read="(file) => uploadImg(file, 'legalBackImg', 'back')" name="legalBackImg" v-else>
                <div class="camera-box center">
                  <van-image :src="datalist.legalBackImg||'https://cdn.teamax-cn.cn/static/Images/pay/material2.png'" class="material-img"	fit="cover" />             
                  <div class="img-load-box" v-if="loadField=='legalBackImg' && showLoad==true">
                    <van-loading color="#fff" vertical size="24">上传中...</van-loading>
                  </div>            
                  <van-image :src="cameraPic" class="camera-img"	fit="cover" />
                </div>
              </van-uploader>
              <span class="material-title">身份证国徽面</span>
            </div>
          </div>
        </div>  
      </div>
    </div>
    <!-- 第二步 -->
    <div class="step-container step-two" v-show="step==2">
      <div class="step-title">账户类型</div>
      <div class="step-body">
        <div class="form-row">
          <van-radio-group class="radio-group row" name="settleAccType" v-model="datalist.settleAccType" direction="horizontal" :disabled="payStatus==3">
            <van-radio class="settle-radio" name="1">对私法人账户</van-radio>
            <van-radio class="settle-radio" name="2">对私非法人账户</van-radio>
            <van-radio class="settle-radio" name="0">对公账户</van-radio>
          </van-radio-group>
        </div>
        <div v-show="datalist.settleAccType==0">
          <div class="form-row">
            <div class="form-title">开户许可证 <em class="required">*</em></div>
            <div class="form-controls rowCenter"> 
              <div class="camera-area center" :class="{'valid-failed':!datalist.balanceAccCardno}">
                <div class="camera-box center" v-if="payStatus==3" @click="previewPic(datalist.accountOpenImg)">
                  <van-image :src="datalist.accountOpenImg||'https://cdn.teamax-cn.cn/static/Images/pay/material5.png'" class="material-img"	fit="cover" />            
                  <van-image :src="cameraPic" class="camera-img"	fit="cover" />
                </div>
                <van-uploader :after-read="(file) => uploadImg(file, 'accountOpenImg', 'account')" name="accountOpenImg" v-else>
                  <div class="camera-box center">
                    <van-image :src="datalist.accountOpenImg||'https://cdn.teamax-cn.cn/static/Images/pay/material5.png'" class="material-img"	fit="cover" />            
                    <div class="img-load-box" v-if="loadField=='accountOpenImg' && showLoad==true">
                      <van-loading color="#fff" vertical size="24">上传中...</van-loading>
                    </div>             
                    <van-image :src="cameraPic" class="camera-img"	fit="cover" />
                  </div>
                </van-uploader>
              </div>
              <div class="eg-box center">
                <span class="eg-text">示例</span>           
                <van-image src="https://cdn.teamax-cn.cn/static/Images/pay/material3.png" class="eg-img"	fit="cover" @click="previewPic('https://cdn.teamax-cn.cn/static/Images/pay/material3.png')" />            
              </div>
            </div>
          </div>
          <div class="form-row form-in">
            <div class="form-title">开户预留手机号 <em class="required">*</em></div>
            <div class="form-controls">
              <van-field
                class='input'
                type="tel"
                v-model="datalist.bankMobile"
                name="bankMobile"
                clearable
                maxlength="11"
                label=""
                :readonly="payStatus==3"
                placeholder="请输入银行卡开户预留手机号"
                :rules="[{ required: true, message: '请输入银行卡开户预留手机号' }]"
              />
            </div>
          </div> 
          <div class="form-row form-in">
            <div class="form-title">结算户名 <em class="required">*</em></div>
            <div class="form-controls">
              <van-field
                class='input'
                clearable
                v-model="datalist.balanceAccName"
                name="balanceAccName"
                label=""
                :readonly="payStatus==3"
                placeholder="结算账户户名"
                :rules="[{ required: true, message: '请填写结算账户户名' }]"
              />
            </div>
          </div>
          <div class="form-row form-in">
            <div class="form-title">结算账号 <em class="required">*</em></div>
            <div class="form-controls">
              <van-field
                class='input'
                clearable
                v-model="datalist.balanceAccCardno"
                name="balanceAccCardno"
                label=""
                :readonly="payStatus==3"
                placeholder="请输入结算账户卡号"
                :rules="[{ required: true, message: '请输入结算账户卡号' }]"
              />
            </div>
          </div>
          <div class="form-row form-in">
            <div class="form-title">开户行地址 <em class="required">*</em></div>
            <div class="form-controls">
              <van-field
                class='input'
                v-model="datalist.openaccAddr"
                name="openaccAddr"
                readonly
                label=""
                placeholder="请选择地区"
                @click="payStatus!=3?showAddr = true:null"
              />
              <van-popup v-model:show="showAddr" round position="bottom">
                <van-cascader
                  v-model="datalist.openaccAddrCode"
                  title="请选择地区"
                  :options="addressData"
                  @close="showAddr = false"
                  @finish="onConfirmAddr"
                />
                <!-- <van-picker
                  :columns="addressData"
                  @cancel="showAddr = false"
                  @confirm="onConfirmAddr"
                /> -->
              </van-popup>
            </div>
          </div>
          <div class="form-row form-in">
            <div class="form-title">开户银行支行 <em class="required">*</em></div>
            <div class="form-controls">
              <van-field
                class='input'
                v-model="datalist.openaccName"
                name="openaccName"
                readonly
                label=""
                placeholder="请选择开户银行支行"
                @click="payStatus!=3?bankPop(0):null"
              />              
            </div>
          </div>      
        </div>
        <div v-show="datalist.settleAccType==1">
          <div class="form-row">
            <div class="form-title">用于结算的银行卡 <em class="required">*</em></div>
            <div class="form-controls rowCenter">
              <div class="camera-area center" :class="{'valid-failed':!datalist.balanceAccCardno1}">
                <div class="camera-box center" v-if="payStatus==3" @click="previewPic(datalist.settleCardFrontImg1)">
                  <van-image :src="datalist.settleCardFrontImg1||'https://cdn.teamax-cn.cn/static/Images/pay/material5.png'" class="material-img"	fit="cover" />            
                  <van-image :src="cameraPic" class="camera-img"	fit="cover" />
                </div>
                <van-uploader :after-read="(file) => uploadImg(file, 'settleCardFrontImg1', 'bank')" name="settleCardFrontImg1" v-else>
                  <div class="camera-box center">
                    <van-image :src="datalist.settleCardFrontImg1||'https://cdn.teamax-cn.cn/static/Images/pay/material5.png'" class="material-img"	fit="cover" />            
                    <div class="img-load-box" v-if="loadField=='settleCardFrontImg1' && showLoad==true">
                      <van-loading color="#fff" vertical size="24">上传中...</van-loading>
                    </div>                        
                    <van-image :src="cameraPic" class="camera-img"	fit="cover" />
                  </div>
                </van-uploader>
                <span class="material-title">银行卡正面</span>
              </div>
              <div class="camera-area center id-reverse" :class="{'valid-failed':!datalist.settleCardBackImg1}">
                <div class="camera-box center" v-if="payStatus==3" @click="previewPic(datalist.settleCardBackImg1)">
                  <van-image :src="datalist.settleCardBackImg1||'https://cdn.teamax-cn.cn/static/Images/pay/material6.png'" class="material-img"	fit="cover" />              
                  <van-image :src="cameraPic" class="camera-img"	fit="cover" />
                </div>
                <van-uploader :after-read="(file) => uploadImg(file, 'settleCardBackImg1')" name="settleCardBackImg1" v-else>
                  <div class="camera-box center">
                    <van-image :src="datalist.settleCardBackImg1||'https://cdn.teamax-cn.cn/static/Images/pay/material6.png'" class="material-img"	fit="cover" />             
                    <div class="img-load-box" v-if="loadField=='settleCardBackImg1' && showLoad==true">
                      <van-loading color="#fff" vertical size="24">上传中...</van-loading>
                    </div>           
                    <van-image :src="cameraPic" class="camera-img"	fit="cover" />
                  </div>
                </van-uploader>
                <span class="material-title">银行卡反面</span>
              </div>
            </div>
          </div>   
          <div class="form-row form-in">
            <div class="form-title">开户预留手机号 <em class="required">*</em></div>
            <div class="form-controls">
              <van-field
                class='input'
                clearable
                type="tel"
                v-model="datalist.bankMobile1"
                name="bankMobile1"
                maxlength="11"
                label=""
                :readonly="payStatus==3"
                placeholder="请输入银行卡开户预留手机号"
                :rules="[{ required: true, message: '请输入银行卡开户预留手机号' }]"
              />
            </div>
          </div> 
          
          <div class="form-row form-in">
            <div class="form-title">结算户名 <em class="required">*</em></div>
            <div class="form-controls">
              <van-field
                class='input'
                clearable
                v-model="datalist.balanceAccName1"
                name="balanceAccName1"
                label=""
                :readonly="payStatus==3"
                placeholder="结算账户户名"
                :rules="[{ required: true, message: '请填写结算账户户名' }]"
              />
            </div>
          </div>
          <div class="form-row form-in">
            <div class="form-title row">
              结算账号 <em class="required">*</em>
              <div class="check-tips row">
                <van-image :src="require('../assets/images/hand.png')" class="hand-img"	fit="cover" />
                <span>请仔细确认您的结算账户是否正确</span>
              </div>
            </div>
            <div class="form-controls">
              <van-field
                class='input'
                clearable
                v-model="datalist.balanceAccCardno1"
                name="balanceAccCardno1"
                label=""
                :readonly="payStatus==3"
                placeholder="请输入结算账户卡号"
                :rules="[{ required: true, message: '请输入结算账户卡号' }]"
              />
            </div>
          </div>
          <div class="form-row form-in">
            <div class="form-title">开户行地址 <em class="required">*</em></div>
            <div class="form-controls">
              <van-field
                class='input'
                v-model="datalist.openaccAddr1"
                name="openaccAddr1"
                readonly
                label=""
                placeholder="请选择地区"
                @click="payStatus!=3?showAddr = true:null"
              />
              <van-popup v-model:show="showAddr" round position="bottom">
                <van-cascader
                  v-model="datalist.openaccAddrCode1"
                  title="请选择地区"
                  :options="addressData"
                  @close="showAddr = false"
                  @finish="onConfirmAddr"
                />
                <!-- <van-picker
                  :columns="addressData1"
                  @cancel="showAddr = false"
                  @confirm="onConfirmAddr"
                /> -->
              </van-popup>
            </div>
          </div>
          <div class="form-row form-in">
            <div class="form-title">开户银行支行 <em class="required">*</em></div>
            <div class="form-controls">
              <van-field
                class='input'
                v-model="datalist.openaccName1"
                name="openaccName1"
                readonly
                label=""
                placeholder="请选择开户银行支行"
                @click="payStatus!=3?bankPop(1):null"
              />             
            </div>
          </div>   
        </div>
        <div v-show="datalist.settleAccType==2">
          <div class="form-row">
            <div class="form-title">用于结算的银行卡 <em class="required">*</em></div>
            <div class="form-controls rowCenter">
              <div class="camera-area center" :class="{'valid-failed':!datalist.balanceAccCardno2}">
                <div class="camera-box center" v-if="payStatus==3" @click="previewPic(datalist.settleCardFrontImg2)">
                  <van-image :src="datalist.settleCardFrontImg2||'https://cdn.teamax-cn.cn/static/Images/pay/material5.png'" class="material-img"	fit="cover" />            
                  <van-image :src="cameraPic" class="camera-img"	fit="cover" />
                </div>
                <van-uploader :after-read="(file) => uploadImg(file, 'settleCardFrontImg2', 'bank')" name="settleCardFrontImg2" v-else>
                  <div class="camera-box center">
                    <van-image :src="datalist.settleCardFrontImg2||'https://cdn.teamax-cn.cn/static/Images/pay/material5.png'" class="material-img"	fit="cover" />            
                    <div class="img-load-box" v-if="loadField=='settleCardFrontImg2' && showLoad==true">
                      <van-loading color="#fff" vertical size="24">上传中...</van-loading>
                    </div>            
                    <van-image :src="cameraPic" class="camera-img"	fit="cover" />
                  </div>
                </van-uploader>
                <span class="material-title">银行卡正面</span>
              </div>
              <div class="camera-area center id-reverse" :class="{'valid-failed':!datalist.settleCardBackImg2}">
                <div class="camera-box center" v-if="payStatus==3" @click="previewPic(datalist.settleCardBackImg2)">
                  <van-image :src="datalist.settleCardBackImg2||'https://cdn.teamax-cn.cn/static/Images/pay/material6.png'" class="material-img"	fit="cover" />              
                  <van-image :src="cameraPic" class="camera-img"	fit="cover" />
                </div>
                <van-uploader :after-read="(file) => uploadImg(file, 'settleCardBackImg2')" name="settleCardBackImg2" v-else>
                  <div class="camera-box center">
                    <van-image :src="datalist.settleCardBackImg2||'https://cdn.teamax-cn.cn/static/Images/pay/material6.png'" class="material-img"	fit="cover" />             
                    <div class="img-load-box" v-if="loadField=='settleCardBackImg2' && showLoad==true">
                      <van-loading color="#fff" vertical size="24">上传中...</van-loading>
                    </div>                   
                    <van-image :src="cameraPic" class="camera-img"	fit="cover" />
                  </div>
                </van-uploader>
                <span class="material-title">银行卡反面</span>
              </div>
            </div>
          </div>     
          <div class="form-row">
            <div class="form-title">非法人身份证 <em class="required">*</em></div>
            <div class="form-controls rowCenter">
              <div class="camera-area center" :class="{'valid-failed':!datalist.unincorporatedId2}">
                <div class="camera-box center" v-if="payStatus==3" @click="previewPic(datalist.unincorporateCardFrontImg2)">
                  <van-image :src="datalist.unincorporateCardFrontImg2||'https://cdn.teamax-cn.cn/static/Images/pay/material1.png'" class="material-img"	fit="cover" />            
                  <van-image :src="cameraPic" class="camera-img"	fit="cover" />
                </div>
                <van-uploader :after-read="(file) => uploadImg(file, 'unincorporateCardFrontImg2', 'face')" name="unincorporateCardFrontImg2" v-else>
                  <div class="camera-box center">
                    <van-image :src="datalist.unincorporateCardFrontImg2||'https://cdn.teamax-cn.cn/static/Images/pay/material1.png'" class="material-img"	fit="cover" />           
                    <div class="img-load-box" v-if="loadField=='unincorporateCardFrontImg2' && showLoad==true">
                      <van-loading color="#fff" vertical size="24">上传中...</van-loading>
                    </div>                 
                    <van-image :src="cameraPic" class="camera-img"	fit="cover" />
                  </div>
                </van-uploader>
                <span class="material-title">身份证头像面</span>
              </div>
              <div class="camera-area center id-reverse" :class="{'valid-failed':!datalist.unincorporateCardBackImg2}">
                <div class="camera-box center" v-if="payStatus==3" @click="previewPic(datalist.unincorporateCardBackImg2)">
                  <van-image :src="datalist.unincorporateCardBackImg2||'https://cdn.teamax-cn.cn/static/Images/pay/material2.png'" class="material-img"	fit="cover" />            
                  <van-image :src="cameraPic" class="camera-img"	fit="cover" />
                </div>
                <van-uploader :after-read="(file) => uploadImg(file, 'unincorporateCardBackImg2')" name="unincorporateCardBackImg2" v-else>
                  <div class="camera-box center">
                    <van-image :src="datalist.unincorporateCardBackImg2||'https://cdn.teamax-cn.cn/static/Images/pay/material2.png'" class="material-img"	fit="cover" />            
                    <div class="img-load-box" v-if="loadField=='unincorporateCardBackImg2' && showLoad==true">
                      <van-loading color="#fff" vertical size="24">上传中...</van-loading>
                    </div>                
                    <van-image :src="cameraPic" class="camera-img"	fit="cover" />
                  </div>
                </van-uploader>
                <span class="material-title">身份证国徽面</span>
              </div>
            </div>
          </div>   
           <div class="form-row">
            <div class="form-title">授权书 <em class="required">*</em></div>
            <div class="form-controls rowCenter"> 
              <div class="camera-area center" :class="{'valid-failed':!datalist.authSettleImg}">
                <div class="camera-box center" v-if="payStatus==3" @click="previewPic(datalist.authSettleImg)">
                  <van-image v-if="datalist.authSettleImg" :src="datalist.authSettleImg" class="material-img"	fit="cover" />
                  <van-image :src="cameraPic" class="camera-img"	fit="cover" />
                </div>
                <van-uploader :after-read="(file) => uploadImg(file, 'authSettleImg')" name="authSettleImg" v-else>
                  <div class="camera-box center">
                    <van-image v-if="datalist.authSettleImg" :src="datalist.authSettleImg" class="material-img"	fit="cover" />            
                    <div class="img-load-box" v-if="loadField=='authSettleImg' && showLoad==true">
                      <van-loading color="#fff" vertical size="24">上传中...</van-loading>
                    </div>                      
                    <van-image :src="cameraPic" class="camera-img"	fit="cover" />
                  </div>
                </van-uploader>
              </div>
              <div class="eg-box center"  @click="downFile">
                <span class="eg-text">模板示例</span>
                <van-image src="https://tm-pay.oss-cn-shenzhen.aliyuncs.com/2022/tmpayimg/exc.png" style="height: 62px;margin-top: 4px;" class="eg-img"	fit="cover" />            
              </div>
            </div>
          </div>
          <div class="form-row form-in">
            <div class="form-title">开户预留手机号 <em class="required">*</em></div>
            <div class="form-controls">
              <van-field
                class='input'
                clearable
                type="tel"
                maxlength="11"
                v-model="datalist.bankMobile2"
                name="bankMobile2"
                label=""
                :readonly="payStatus==3"
                placeholder="请输入银行卡开户预留手机号"
                :rules="[{ required: true, message: '请输入银行卡开户预留手机号' }]"
              />
            </div>
          </div> 
          
          <div class="form-row form-in">
            <div class="form-title">结算户名 <em class="required">*</em></div>
            <div class="form-controls">
              <van-field
                class='input'
                clearable
                v-model="datalist.balanceAccName2"
                name="balanceAccName2"
                label=""
                :readonly="payStatus==3"
                placeholder="结算账户户名"
                :rules="[{ required: true, message: '请填写结算账户户名' }]"
              />
            </div>
          </div>
          <div class="form-row form-in">
            <div class="form-title row">
              结算账号 <em class="required">*</em>
              <div class="check-tips row">
                <van-image :src="require('../assets/images/hand.png')" class="hand-img"	fit="cover" />
                <span>请仔细确认您的结算账户是否正确</span>
              </div>
            </div>
            <div class="form-controls">
              <van-field
                class='input'
                clearable
                v-model="datalist.balanceAccCardno2"
                name="balanceAccCardno2"
                label=""
                :readonly="payStatus==3"
                placeholder="请输入结算账户卡号"
                :rules="[{ required: true, message: '请输入结算账户卡号' }]"
              />
            </div>
          </div>
          <div class="form-row form-in">
            <div class="form-title">开户行地址 <em class="required">*</em></div>
            <div class="form-controls">
              <van-field
                class='input'
                v-model="datalist.openaccAddr2"
                name="openaccAddr2"
                readonly
                label=""
                placeholder="请选择地区"
                @click="payStatus!=3?showAddr = true:null"
              />
              <van-popup v-model:show="showAddr" round position="bottom">
                <van-cascader
                  v-model="datalist.openaccAddrCode2"
                  title="请选择地区"
                  :options="addressData"
                  @close="showAddr = false"
                  @finish="onConfirmAddr"
                />
                <!-- <van-picker
                  :columns="addressData2"
                  @cancel="showAddr = false"
                  @confirm="onConfirmAddr"
                /> -->
              </van-popup>
            </div>
          </div>
          <div class="form-row form-in">
            <div class="form-title">开户银行支行 <em class="required">*</em></div>
            <div class="form-controls">
              <van-field
                class='input'
                v-model="datalist.openaccName2"
                name="openaccName2"
                readonly
                label=""
                placeholder="请选择开户银行支行"
                @click="payStatus!=3?bankPop(2):null"
              />              
            </div>
          </div>   
        </div>
      </div>
    </div>
    <!-- 第三步 -->
    <div class="step-container step-three" v-show="step==3">
      <div class="step-body">
        <div class="form-row">
          <div class="form-title">门店门头照 <em class="required">*</em></div>
          <div class="form-controls rowCenter">
            <div class="camera-area center" :class="{'valid-failed':!datalist.headStoreImg}">
              <div class="camera-box center" v-if="payStatus==3" @click="previewPic(datalist.headStoreImg)">
                <van-image v-if="datalist.headStoreImg" :src="datalist.headStoreImg" class="material-img"	fit="cover" />              
                <van-image :src="cameraPic" class="camera-img"	fit="cover" />
              </div>
              <van-uploader :after-read="(file) => uploadImg(file, 'headStoreImg')" name="headStoreImg" v-else>
                <div class="camera-box center">
                  <van-image v-if="datalist.headStoreImg" :src="datalist.headStoreImg" class="material-img"	fit="cover" />            
                  <div class="img-load-box" v-if="loadField=='headStoreImg' && showLoad==true">
                    <van-loading color="#fff" vertical size="24">上传中...</van-loading>
                  </div>                      
                  <van-image :src="cameraPic" class="camera-img"	fit="cover" />
                </div>
              </van-uploader>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-title">门店内景照 <em class="required">*</em></div>
          <div class="form-controls rowCenter">
            <div class="camera-area center" :class="{'valid-failed':!datalist.inStoreImg}">
              <div class="camera-box center" v-if="payStatus==3" @click="previewPic(datalist.inStoreImg)">
                <van-image v-if="datalist.inStoreImg" :src="datalist.inStoreImg" class="material-img"	fit="cover" />              
                <van-image :src="cameraPic" class="camera-img"	fit="cover" />
              </div>
              <van-uploader :after-read="(file) => uploadImg(file, 'inStoreImg')" name="inStoreImg" v-else>
                <div class="camera-box center">
                  <van-image v-if="datalist.inStoreImg" :src="datalist.inStoreImg" class="material-img"	fit="cover" />             
                  <div class="img-load-box" v-if="loadField=='inStoreImg' && showLoad==true">
                    <van-loading color="#fff" vertical size="24">上传中...</van-loading>
                  </div>            
                  <van-image :src="cameraPic" class="camera-img"	fit="cover" />
                </div>
              </van-uploader>
            </div>
          </div>
        </div>
        <div class="form-row" v-if="channelCode == 'YS'||channelCode == 'LKL'">
          <div class="form-title">门店收银台照 <em class="required">*</em></div>
          <div class="form-controls rowCenter">
            <div class="camera-area center" :class="{'valid-failed':!datalist.cashStoreImg}">
              <div class="camera-box center" v-if="payStatus==3" @click="previewPic(datalist.cashStoreImg)">
                <van-image v-if="datalist.cashStoreImg" :src="datalist.cashStoreImg" class="material-img"	fit="cover" />              
                <van-image :src="cameraPic" class="camera-img"	fit="cover" />
              </div>
              <van-uploader :after-read="(file) => uploadImg(file, 'cashStoreImg')" name="cashStoreImg" v-else>
                <div class="camera-box center">
                  <van-image v-if="datalist.cashStoreImg" :src="datalist.cashStoreImg" class="material-img"	fit="cover" />              
                  <div class="img-load-box" v-if="loadField=='cashStoreImg' && showLoad==true">
                    <van-loading color="#fff" vertical size="24">上传中...</van-loading>
                  </div>              
                  <van-image :src="cameraPic" class="camera-img"	fit="cover" />
                </div>
              </van-uploader>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 第四步 -->
    <div class="step-container step-four" v-show="step==4">
      <div class="step-body">
        <div class="form-row form-in">
          <div class="form-title">协议类型</div>
          <div class="form-controls">
            <input type='text' class='input xieyi-in' readonly value="电子协议" placeholder="协议类型" placeholder-style="color:#BCBCBC" />
          </div>
        </div>
        <div class="form-row form-in">
          <div class="form-title">支付宝账号</div>
          <div class="form-controls">
            <van-field
              class='input'
              clearable
              type="text"
              name="zfbAccount"
              v-model="datalist.zfbAccount"
              label=""
              :readonly="payStatus==3"
              placeholder="请输入支付宝账号"
            />
          </div>
        </div>
        <div class="form-row form-in">
          <div class="form-title">联系邮箱 <em class="required">*</em></div>
          <div class="form-controls">
            <van-field
              class='input'
              clearable
              type="email"
              name="contactsEmail"
              v-model="datalist.contactsEmail"
              label=""
              :readonly="payStatus==3"
              placeholder="请输入联系邮箱"
            />
          </div>
        </div>
        <div class="form-row form-in">
          <div class="form-title">联系手机 <em class="required">*</em></div>
          <div class="form-controls">
            <van-field
              class='input'
              type="tel"
              clearable
              maxlength="11"
              name="contactsPhone"
              v-model="datalist.contactsPhone"
              label=""
              :readonly="payStatus==3"
              placeholder="请输入联系手机"
            />
          </div>
        </div>
        <div class="form-row form-in" v-if="payStatus!=3">
          <div class="form-title">短信验证码 <em class="required">*</em></div>
          <div class="form-controls bb1">
            <van-field
              class='input msg-code'
              name="verificationCode"
              v-model="datalist.verificationCode"
              clearable
              maxlength="6"
              label=""
              autocomplete="one-time-code"
              placeholder="请输入短信验证码"
            />
            <div class="getcode" v-show="showcount" @click="SendVerif">获取短信验证码</div>
            <div class="getcode" v-show="!showcount">{{ count }}s后获取</div>
          </div>
        </div>
        <div class="tips">
          <span>温馨提示：商户申请开通支付业务并审核后，将发送签约链接至联系人手机短信，提交申请后，请及时查看进度并完成签约，“联系邮箱”用于接收电子协议，请确认无误后提交。</span>
        </div>
      </div>
    </div>
    <!-- 操作按钮 -->    
    <div class="opt-box">
      <div class="opt first" v-if="step == 1">
        <span class='btn apply-btn center float-ani' @click="applyNext">下一步</span>
      </div>
      <div class="opt opt-2" v-else>
        <div class="btn prev-btn center" @click="applyPrev">上一步</div>
        <div class="btn next-btn center float-ani" @click="applyNext">{{step==4?'提交':'下一步'}}</div>
      </div>
    </div>  
    <!-- 法人认证提示弹窗 -->
    <van-popup v-model:show="showFace" closeable round position="bottom" close-on-click-overlay="false">
      <div class="PullUp">
        <div class='rowCenter title'>
          法人认证提示
        </div>
        <div class='column PullUpAuth'>
          <div class="PullUpContent column">
            <div class="explain">
              本次认证需要通过<span class="faren">法人</span>人脸识别验证身份信息并确认后，可证实本次申请开通的进件资料准确合法。
            </div>
            <span class="auth-label">方式1</span>
            <span class='auth-type center' @click="authFace">立即认证</span>
            <span class="p-tips">如您是法人本人操作，选该方式进行认证</span>
            <span class="auth-label">方式2</span>
            <span class='auth-type center' @click="showSharePop=true">邀请法人认证</span>
            <span class="p-tips">如您是非法人操作，请点邀请法人认证</span>
          </div>
        </div>
      </div>
    </van-popup>
    <!-- 滑块验证码 -->
    <div v-if="showVerity">
      <Captcha
        :ref="(el)=>dialogopen = el"
        :l="42"
        :r="10"
        :w="catcha.w"
        :h="catcha.h"
        :sw="catcha.sw"
        :sh="catcha.sh"
        :blocky="catcha.blocky"
        :imgurl="catcha.imgurl"
        :miniimgurl="catcha.miniimgurl"
        :slider-text="catcha.text"
        @success="onSuccess"
        @fail="onFail"
        @refresh="onRefresh"
        @hideverify="hideVerify"
      />
    </div>
    
    <!--分享指引-->
    <div v-if="showSharePop">
      <ShareGuide :legalName="datalist.legalName" @close="showSharePop=false" />
    </div>

    <!-- 开户行支行弹窗 -->
    <div v-if="showBank==true">
      <van-popup v-model:show="showBank" round position="bottom">
        <MyPicker 
          :isShowBank="showBank"
          :addressStandardId="addressStandardId"
          :initBankId="initBankId"
          :initBankName="initBankName"
          @confirm="onConfirmBank"
          @hide="hideBank">
        </MyPicker>
      </van-popup>
    </div>

  </div>
</template>

<script>
import { reactive, onMounted, onBeforeUnmount, toRefs, nextTick, ref, getCurrentInstance } from 'vue'
import { mapGetters,useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import md5 from "js-md5"  //导入
import Captcha from '@/components/Captcha'
import ShareGuide from '@/components/ShareGuide'
import MyPicker from '@/components/MyPicker'
import { uploadFileApi } from "@/api/common";
import { 
  orcImgApi, 
  listRegion, 
  getKaptchaImg, 
  checkKaptchaImg, 
  sendMessageApi, 
  applySubmitApi,
  getMerApplyInfo
} from "@/api/apply";
import { handleTree, setLocal, getLocal, removeLocal } from "@/utils/index";
import { Toast,Notify,ImagePreview } from 'vant'
import { wechatConfig } from "@/utils/wechatSdk";
export default {
  name: 'apply',
  computed: {
    ...mapGetters([
      'enrollState'   ////默认显示会员页面，100未查询 0: 未提交审核 1：资料已提交 2：已经人脸核身（审核中） 3：审核通过 4：审核失败
    ])
  },
  components: {
    Captcha,
    ShareGuide,
    MyPicker
  },
  setup(){
    const showcount = ref(true);
    const timer = ref();
    const TIME_COUNT = ref(60);
    const count = ref();
    const dialogopen = ref(null); //本质是reactive({value:null})

    const store = useStore()
    const router = useRouter();
    const route = useRoute()
    const state = reactive({
      step: 1,  //当前步骤
      cameraPic: require('../assets/images/camera.png'),
      showSharePop:false, //是否显示分享指引弹窗
      datalist:{
        //第一步
        merType: '0',   //账户类型 0个体商户 1企业商户
        usccImg: null,  //营业执照照片
        businessLicence: null,  //营业执照全称
        businessLicenceNo: null,  //营业执照号码
        businessLicenceSdate: null,  //营业执照开始日期，格式：yyyy-MM-dd
        businessLicenceEdate: null,  //营业执照结束日期，格式：yyyy-MM-dd，长期填2999-12-31
        registeredAddress: null,  //营业执照注册地址
        merAddress: null,   //商户经营地址（省，市，区，需要录入城市地区表中的ID）
        merAddressDetail: null,   //商户详细经营地址

        legalFrontImg: null,   //法人身份证正面照
        legalName: null,   //法人名称
        cardNo: null,   //法人身份证号码
        crpAddr: null,   //法人地址
        crpGender: null,   //法人性别（0男 1女）默认0
        
        legalBackImg: null,    //法人身份证反面照片(URL)
        cardStartDate: null,    //法人身份证发证日，格式：yyyy-MM-dd
        cardStartEnd: null,    //法人身份证过期日，格式：yyyy-MM-dd，长期输入2999-12-31

        //第二步
        settleAccType: '1',       //0对公账户 1对私法人账户 2对私非法人账户
        accountOpenImg: null,   //开户许可证图片(URL) [企业商户进件必须][对公账户]
        bankMobile: null,   //银行预留手机号
        balanceAccName: null,   //结算账户户名
        balanceAccCardno: null, //结算账户卡号     
        openaccAddr: null,      //开户行地址名称   
        openaccAddrCode: null,    //开户行地址  
        openaccAddrCodeId: null,    //开户行地址id 数组
        openaccAddrStandardId: null,  //开户行地址id 数组
        openaccId: null,    //开户银行支行
        openaccName: null,    //开户银行支行名称

        settleCardFrontImg1: null,   //结算银行正面
        settleCardBackImg1: null,   //结算银行反面
        bankMobile1: null,   //银行预留手机号
        balanceAccName1: null,   //结算账户户名
        balanceAccCardno1: null, //结算账户卡号     
        openaccAddr1: null,      //开户行地址名称   
        openaccAddrCode1: null,    //开户行地址
        openaccAddrCodeId1: null,    //开户行地址id 数组
        openaccAddrStandardId1: null,  //开户行地址id 数组
        openaccId1: null,    //开户银行支行
        openaccName1: null,    //开户银行支行名称

        settleCardFrontImg2: null,   //结算银行正面
        settleCardBackImg2: null,   //结算银行反面
        unincorporateCardFrontImg2: null,  //非法人身份证正面
        unincorporateCardBackImg2: null,  //非法人身份证反面
        unincorporatedId2: null,   //非法人身份证号
        unincorporatedName2: null,   //非法人姓名
        bankMobile2: null,   //银行预留手机号
        balanceAccName2: null,   //结算账户户名
        balanceAccCardno2: null, //结算账户卡号  
        openaccAddr2: null,      //开户行地址名称   
        openaccAddrCode2: null,    //开户行地址   
        openaccAddrCodeId2: null,    //开户行地址id 数组
        openaccAddrStandardId2: null,  //开户行地址id 数组
        openaccId2: null,    //开户银行支行
        openaccName2: null,    //开户银行支行名称

        //第三步
        headStoreImg: null, //商户门头照片
        inStoreImg: null, //商户店内照片
        cashStoreImg: null, //商户收银台照片

        // 第四步
        zfbAccount: null,   //支付宝账号
        contactsEmail: null,  //联系邮箱
        contactsPhone: null,  //联系电话
        isWxAuth: 0, //是否微信授权
        smsUuid: null,  //短信验证码的uuid
        verificationCode: null,  //短信验证码        
      },

      loadField:null,   //当前图片上传字段
      showLoad:false,   //是否显示图片上传中

      reginList: [],    //地址原始列表
      showAddr: false,  //是否显示开户银行省市区地址
      addressData: null,  //省市区联动数据

      addressRegion: [],
      addressRegionId: [],  
      addressStandardId: [],  
      showBank: false,  //是否显示开户银行卡弹出
      initBankId: '',  //银行卡初始值
      initBankName: '',  //银行卡初始值

      showFace: false,  //是否显示人脸认证弹窗

      showVerity: false,  //是否显示滑块验证码      
      catcha: {
        blocky: 0,  //滑块y位置
        imgurl: '',   //大图url
        miniimgurl: '',   //小图url
        text: '向右滑动完成拼图', 
        h: 150,   //大图高
        w: 300,   //大图宽
        sh: 50,   //小图高
        sw: 40,   //小图宽
        modifyImg: ''
      }, // 图片验证码传值
      imageKey:null,  //图形验证码key
      verifyKey:null,  //图形验证码key
      merId:null, //商户id
      channelCode: 'LKL', // 进件渠道 YS=银盛、ZFB=支付宝官方、WX=微信官方  LKL 拉卡拉
      payStatus: 100,   //支付状态 100未查询 0: 未提交审核 1：资料已提交 2：已经人脸核身（审核中） 3：审核通过 4：审核失败
    })  
    const { proxy } = getCurrentInstance() // as any  原型对象方法
    proxy.$md5=md5   //md5转换为vue原型
    
    onMounted(async () => {
      //获取地址列表
      listRegion().then(res => {        
        state.reginList = res.data;
        if(res.data){
          let addr =  handleTree(res.data);
          state.addressData = addr;
        }
        
        state.channelCode = route.query && route.query.type ? route.query.type : 'LKL';
        state.payStatus = route.query && route.query.status ? route.query.status : 0;

        if(state.channelCode == 'YS'){          
          document.title = '天美支付';
        }else if(state.channelCode == 'WX'){  
          document.title = '天美支付-微信支付';
        }else if(state.channelCode == 'ZFB'){  
          document.title = '天美支付-支付宝支付';
        }else{
           document.title = '天美支付';
        }

        if(store.getters['enrollState'] == 100 || store.getters['enrollState'] == 0 || (route.query && route.query.status == 0)){
          //获取表单缓存
          let applyData = getLocal('applyData'+state.channelCode);
          if(applyData){
            state.datalist = applyData;
          }
        }else{
          let applyData = getLocal('applyData'+state.channelCode);
          if(applyData){
            removeLocal('applyData'+state.channelCode);
          }
          //查询商户资料
          getMerApplyInfo(state.channelCode).then(res => {
            if(res && res.data){
              const data = res.data;
              //第一步
              state.datalist.merType = data.merBaseInfo.merType+'';
              state.datalist.usccImg = data.merBaseInfo.usccImg;
              state.datalist.businessLicence = data.merBaseInfo.businessLicence;
              state.datalist.businessLicenceEdate = data.merBaseInfo.businessLicenceEdate;
              state.datalist.businessLicenceNo = data.merBaseInfo.businessLicenceNo;
              state.datalist.businessLicenceSdate = data.merBaseInfo.businessLicenceSdate;
              state.datalist.businessLicenceSdate = data.merBaseInfo.businessLicenceSdate;
              state.datalist.merAddress = data.merBaseInfo.merAddress;
              state.datalist.merAddressDetail = data.merBaseInfo.merAddressDetail;
              state.datalist.legalFrontImg = data.merBaseInfo.legalFrontImg;
              state.datalist.legalBackImg = data.merBaseInfo.legalBackImg;
              state.datalist.cardNo = data.merBaseInfo.cardNo;
              state.datalist.cardStartDate = data.merBaseInfo.cardStartDate;
              state.datalist.cardStartEnd = data.merBaseInfo.cardStartEnd;
              state.datalist.crpAddr = data.merBaseInfo.crpAddr;
              state.datalist.crpGender = data.merBaseInfo.crpGender;
              state.datalist.legalName = data.merBaseInfo.legalName;

              //第二步
              state.datalist.accountOpenImg = data.merSettleInfo.accountOpenImg;
              state.datalist.unincorporateCardBackImg2 = data.merSettleInfo.unincorporateCardBackImg;
              state.datalist.unincorporateCardFrontImg2 = data.merSettleInfo.unincorporateCardFrontImg;
              state.datalist.unincorporatedId2 = data.merSettleInfo.unincorporatedId;
              state.datalist.unincorporatedName2 = data.merSettleInfo.unincorporatedName;

              if(data.merSettleInfo.balanceAccType == '11'){  //11=对私借记卡，21=对公借记卡
                if(data.merSettleInfo.codeLegalPersonAcc == '1'){ //0: 非法人结算, 1:法人结算
                  state.datalist.settleAccType = '1';
                }else{
                  state.datalist.settleAccType = '2';
                }
              }else{
                state.datalist.settleAccType = '0';
              }
              //开户行地址，开户行支行
              if(state.datalist.settleAccType == '0'){
                state.datalist.balanceAccCardno = data.merSettleInfo.balanceAccCardno;
                state.datalist.balanceAccName = data.merSettleInfo.balanceAccName;
                state.datalist.bankMobile = data.merSettleInfo.bankMobile;
                state.datalist.settleCardBackImg = data.merSettleInfo.settleCardBackImg;
                state.datalist.settleCardFrontImg = data.merSettleInfo.settleCardFrontImg;

                state.datalist.openaccAddr = data.merSettleInfo.openaccAddrName?data.merSettleInfo.openaccAddrName.join('/'):null;
                state.datalist.openaccAddrCode = data.merSettleInfo.openaccAddrCode?data.merSettleInfo.openaccAddrCode[2]:null;
                state.datalist.openaccAddrCodeId = data.merSettleInfo.openaccAddrCode;
                state.datalist.openaccAddrStandardId = data.merSettleInfo.openaccAddrStandardId;
                state.datalist.openaccId = data.merSettleInfo.openaccId;
                state.datalist.openaccName = data.merSettleInfo.openaccName;
              }else if(state.datalist.settleAccType == '1'){
                state.datalist.balanceAccCardno1 = data.merSettleInfo.balanceAccCardno;
                state.datalist.balanceAccName1 = data.merSettleInfo.balanceAccName;
                state.datalist.bankMobile1 = data.merSettleInfo.bankMobile;
                state.datalist.settleCardBackImg1 = data.merSettleInfo.settleCardBackImg;
                state.datalist.settleCardFrontImg1 = data.merSettleInfo.settleCardFrontImg;

                state.datalist.openaccAddr1 = data.merSettleInfo.openaccAddrName?data.merSettleInfo.openaccAddrName.join('/'):null;
                state.datalist.openaccAddrCode1 = data.merSettleInfo.openaccAddrCode?data.merSettleInfo.openaccAddrCode[2]:null;
                state.datalist.openaccAddrCodeId1 = data.merSettleInfo.openaccAddrCode;
                state.datalist.openaccAddrStandardId1 = data.merSettleInfo.openaccAddrStandardId;
                state.datalist.openaccId1 = data.merSettleInfo.openaccId;
                state.datalist.openaccName1 = data.merSettleInfo.openaccName;
              }else if(state.datalist.settleAccType == '2'){
                state.datalist.balanceAccCardno2 = data.merSettleInfo.balanceAccCardno;
                state.datalist.balanceAccName2 = data.merSettleInfo.balanceAccName;
                state.datalist.bankMobile2 = data.merSettleInfo.bankMobile;
                state.datalist.settleCardBackImg2 = data.merSettleInfo.settleCardBackImg;
                state.datalist.settleCardFrontImg2 = data.merSettleInfo.settleCardFrontImg;
                
                state.datalist.openaccAddr2 = data.merSettleInfo.openaccAddrName?data.merSettleInfo.openaccAddrName.join('/'):null;
                state.datalist.openaccAddrCode2 = data.merSettleInfo.openaccAddrCode?data.merSettleInfo.openaccAddrCode[2]:null;
                state.datalist.openaccAddrCodeId2 = data.merSettleInfo.openaccAddrCode;
                state.datalist.openaccAddrStandardId2 = data.merSettleInfo.openaccAddrStandardId;
                state.datalist.openaccId2 = data.merSettleInfo.openaccId;
                state.datalist.openaccName2 = data.merSettleInfo.openaccName;
              }

              //第三步 门店照片
              state.datalist.cashStoreImg = data.merStoreInfo.cashStoreImg;
              state.datalist.headStoreImg = data.merStoreInfo.headStoreImg;
              state.datalist.inStoreImg = data.merStoreInfo.inStoreImg;

              //第四步 联系人信息
              state.datalist.zfbAccount = data.merAgreementInfo.zfbAccount;
              state.datalist.contactsEmail = data.merAgreementInfo.contactsEmail;
              state.datalist.contactsPhone = data.merAgreementInfo.contactsPhone;
            }
          }); 
        }
      }); 
    })  
    //选择地址
    const onConfirmAddr = ({ selectedOptions }) => {
      let names = [];
      let ids = [];
      let standardIds = [];
      for(let t of selectedOptions){
        names.push(t.text);
        ids.push(t.value);
        standardIds.push(t.standardId);
      }
      names = names.join('/')
      if(state.datalist.settleAccType == '0'){
        if(names != state.datalist.openaccAddr){
          state.datalist.openaccAddr = names;
          state.datalist.openaccAddrCodeId = ids;
          state.datalist.openaccAddrStandardId = standardIds;
          state.datalist.openaccId = null;
          state.datalist.openaccName = null;
        }
      }else if(state.datalist.settleAccType == '1'){
        if(names != state.datalist.openaccAddr1){
          state.datalist.openaccAddr1 = names;
          state.datalist.openaccAddrCodeId1 = ids;
          state.datalist.openaccAddrStandardId1 = standardIds;
          state.datalist.openaccId1 = null;
          state.datalist.openaccName1 = null;
        }
      }else if(state.datalist.settleAccType == '2'){
        if(names != state.datalist.openaccAddr2){
          state.datalist.openaccAddr2 = names;
          state.datalist.openaccAddrCodeId2 = ids;
          state.datalist.openaccAddrStandardId2 = standardIds;
          state.datalist.openaccId2 = null;
          state.datalist.openaccName2 = null;
        }
      }
      state.showAddr = false;
    };
    //选择开户行
    const bankPop = (type) => {
      if(type == 1){
        if(!state.datalist.openaccAddrCode1){
          Toast('请先选择开户行地址');
          return false;
        }
        state.addressStandardId = state.datalist.openaccAddrStandardId1;
        state.initBankId = state.datalist.openaccId1||'';
        state.initBankName = state.datalist.openaccName1||'';
      }else if(type == 2){
        if(!state.datalist.openaccAddrCode2){
          Toast('请先选择开户行地址');
          return false;
        }
        state.addressStandardId = state.datalist.openaccAddrStandardId2;
        state.initBankId = state.datalist.openaccId2||'';
        state.initBankName = state.datalist.openaccName2||'';
      }else{
        if(!state.datalist.openaccAddrCode){
          Toast('请先选择开户行地址');
          return false;
        }
        state.addressStandardId = state.datalist.openaccAddrStandardId;
        state.initBankId = state.datalist.openaccId||'';
        state.initBankName = state.datalist.openaccName||'';
      }
      state.showBank = true;
    }
    //选择开户银行卡
    const onConfirmBank = (value) => {
      if(state.datalist.settleAccType == '0'){
        if(value.id != state.datalist.openaccId){
          state.datalist.openaccId = value.id;
          state.datalist.openaccName = value.bankName;
        }
      }else if(state.datalist.settleAccType == '1'){
        if(value.id != state.datalist.openaccId1){
          state.datalist.openaccId1 = value.id;
          state.datalist.openaccName1 = value.bankName;
        }
      }else if(state.datalist.settleAccType == '2'){
        if(value.id != state.datalist.openaccId2){
          state.datalist.openaccId2 = value.id;
          state.datalist.openaccName2 = value.bankName;
        }
      }
      state.showBank = false;
    }
    //隐藏
    const hideBank = () => {
      state.showBank = false;
    }
    
    //图片上传
    const uploadImg = (file, name, orc) => {
      file.status = 'uploading';
      file.message = '上传中...';
      state.loadField = name;
      state.showLoad = true;
      let that = this;
      let param = {
        file:file.file
      }
      const forms = new FormData()
      // 这里的file表示给后台传的属性名字，这里看接口文档需要传的的属性
      forms.append('file', file.file) // 获取上传图片信息
      // this.files = file;
      uploadFileApi(forms).then(res => {
        if (res.code == 200) {
          state.datalist[name] = res.data.url;
          // that.$set(state.datalist, ''+name, res.data.url);
          // ORC智能识别
          if(orc){
            getOrcAutoFill(name,orc,res.data.url);
          }else{
            state.showLoad = false;
          }
        } else {
          Toast(res.msg);
        }
      });
    }
    //orc图片识别
    const getOrcAutoFill= (field, orc, imgurl) => {
      let _url = '';
      let param = {};
      //orc图片识别字段 biz营业执照 face身份证正面照  back身份证反面照 bank银行卡  account开户许可证
      if(orc == 'buz'){
        _url = '/common/orc/business?businessImg='+imgurl;
      }
      if(orc == 'face'){
        _url = '/common/orc/idcard/face?imgUrl='+imgurl;
      }
      if(orc == 'back'){
        _url = '/common/orc/idcard/back?imgUrl='+imgurl;
      }
      if(orc == 'account'){
        _url = '/common/orc/bankAccount?imgUrl='+imgurl;
      }
      if(orc == 'bank'){
        _url = '/common/orc/bankCard?bankCardImg='+imgurl;
      }
      orcImgApi(_url).then(res => {
        state.showLoad = false;
        if (res.code == 200) {
          if (res && res.data) {
            let data = res.data;
            //营业执照
            if(field == 'usccImg'){
              state.datalist.businessLicence = data.companyName;
              state.datalist.businessLicenceNo = data.creditCode;
              state.datalist.businessLicenceSdate = data.validFromDate;
              state.datalist.businessLicenceEdate = data.validToDate;
              state.datalist.registeredAddress = data.businessAddress;
              state.datalist.merAddress = data.merAddress;
              state.datalist.merAddressDetail = data.merAddressDetail;
              state.datalist.tips = data.businessScope;
            }
            //法人身份证正面照
            if(field == 'legalFrontImg'){
              state.datalist.legalName = data.name;
              state.datalist.cardNo = data.idNumber;
              state.datalist.crpAddr = data.address;
              state.datalist.crpGender = data.sex === "女" ? "1" : "0";
            }
            //法人身份证反面照
            if(field == 'legalBackImg'){
              state.datalist.cardStartDate = data.dateValidFrom;
              state.datalist.cardStartEnd = data.dateValidTo;
            }
            //开户许可证
            if(field == 'accountOpenImg'){
              state.datalist.balanceAccName = data.customerName;
              state.datalist.balanceAccCardno = data.approvalNumber;
            }
            //对私法人银行卡
            if(field == 'settleCardFrontImg1'){
              state.datalist.balanceAccCardno1 = data.cardNumber;
            }
            //对私非法人银行卡
            if(field == 'settleCardFrontImg2'){
              state.datalist.balanceAccCardno2 = data.cardNumber;
            }
            //对私非法人身份证正面照
            if(field == 'unincorporateCardFrontImg2'){
              state.datalist.balanceAccName2 = data.name;
              state.datalist.unincorporatedName2 = data.name;
              state.datalist.unincorporatedId2 = data.idNumber;
            }
          }else{
            //营业执照
            if(field == 'usccImg'){
              state.datalist.businessLicence = null;
              state.datalist.businessLicenceNo = null;
              state.datalist.businessLicenceSdate = null;
              state.datalist.businessLicenceEdate = null;
              state.datalist.registeredAddress = null;
              state.datalist.merAddress = null;
              state.datalist.merAddressDetail = null;
              state.datalist.tips = null;
              Toast('未识别出法人身份证正面照信息，请重新上传，确保拍照清晰！');
            }
            //法人身份证正面照
            if(field == 'legalFrontImg'){
              state.datalist.legalName = null;
              state.datalist.cardNo = null;
              state.datalist.crpAddr = null;
              state.datalist.crpGender = null;
              Toast('未识别出法人身份证正面照信息，请重新上传，确保拍照清晰！');
            }
            //法人身份证反面照
            if(field == 'legalBackImg'){
              state.datalist.cardStartDate = null;
              state.datalist.cardStartEnd = null;
              Toast('未识别出法人身份证反面照信息，请重新上传，确保拍照清晰！');
            }
            //开户许可证
            if(field == 'accountOpenImg'){
              state.datalist.balanceAccName = null;
              state.datalist.balanceAccCardno = null;
            }
            //对私法人银行卡
            if(field == 'settleCardFrontImg1'){
              state.datalist.balanceAccCardno1 = null;
            }
            //对私非法人银行卡
            if(field == 'settleCardFrontImg2'){
              state.datalist.balanceAccCardno2 = null;
            }
            //对私非法人身份证正面照
            if(field == 'unincorporateCardFrontImg2'){
              state.datalist.balanceAccName2 = null;
              state.datalist.unincorporatedName2 = null;
              state.datalist.unincorporatedId2 = null;
              Toast('未识别出非法人身份证正面照信息，请重新上传，确保拍照清晰！');
            }
          }
        } else {
          Toast(res.msg);
        }
      });
    }

    //立即认证
    const authFace = () => {
      if(!state.datalist.cardNo){
        Toast('请上传法人身份证')
        // Notify({ message: '请上传法人身份证'}); 
        return false;       
      }
      router.push({
        name: "face",
        query: { 
          merid:state.merId,
          channelcode: state.channelCode,
          type:0,
          time:Date.now()
        }
      });
    }
    //图片预览
    const previewPic = (pic) => {
      ImagePreview([pic])
    }
    //下载模板
    const downFile=()=>{
       // 创建一个<a></a>标签
        const a = document.createElement('a');
        a.href = "https://tm-pay.oss-cn-shenzhen.aliyuncs.com/docfile/%E9%99%84%E4%BB%B63%EF%BC%9A%E8%B5%84%E9%87%91%E6%B8%85%E7%AE%97%E6%8E%88%E6%9D%83%E5%A7%94%E6%89%98%E4%B9%A6.doc";
        var name="资金清算授权委托书.doc";
        a.download = name;
        // 障眼法藏起来a标签
        a.style.display = 'none';
        // 将a标签追加到文档对象中
        document.body.appendChild(a);
        // 模拟点击了<a>标签,会触发<a>标签的href的读取,浏览器就会自动下载了
        a.click();
        // 一次性的,用完就删除a标签
        a.remove();
    }
    
    //显示滑块验证码
    const SendVerif = () => {
      if (!(/^(\((\+)?86\)|((\+)?86)?)0?1[3456789]\d{9}$/.test(state.datalist.contactsPhone))) {
        Toast('请输入合法手机号！');
        return false;
      }
      getImageVerifyCode()
      // setTimeout(() => {
      //   state.showVerity = true;
      // }, 500)
    }
    //隐藏滑块验证码
    const hideVerify = () => {
      state.showVerity = false;
    }
    //获取图形验证码
    const getImageVerifyCode = () => {
      let param = {
        uniqueId: state.datalist.contactsPhone
      }
      getKaptchaImg(param).then(res => {
        if (res && res.data) {
          var imgobj = res.data
          state.imageKey = imgobj.imageKey;
          state.catcha.blocky = imgobj.points[0].y;
          state.catcha.imgurl = imgobj.images[0];
          state.catcha.miniimgurl = imgobj.images[1];        
          state.showVerity = true;
          nextTick(() => {
            dialogopen.value?.reset(imgobj.points[0].y);
          })
        }else{
          Toast(res.msg||'获取图形验证码失败，请稍后重试！')
          // Notify({ 
          //   message: res.msg||'获取图形验证码失败，请稍后重试！'
          // });
        }
      })
    }
    //验证成功
    const verifysuccess = () => {
      console.log('fail')
    }
    //滑块验证成功
    const onSuccess = (left) => {
      // this.loginForm.distance = left
      // console.log('succss', left)
      // 验证是否成功checkKaptchaImg是后台验证接口方法  
      let verifyContent = [{ X: parseInt(left), Y: state.catcha.blocky }];
      let md5Content = proxy.$md5(JSON.stringify(verifyContent) + '&' + state.imageKey);  
      // proxy.$md5(formState.password)
      let param = {
        uniqueId: state.datalist.contactsPhone,
        imageKey: state.imageKey,
        verifyContent: JSON.stringify(verifyContent),
        md5Content: md5Content.toUpperCase()
      }
      checkKaptchaImg(param).then(res => {
        if (res && res.code == 200 && res.data && res.data.isError == false) {
          state.verifyKey = res.data.verifyKey;
          dialogopen.value?.handleSuccess();
          setTimeout(() => {
            state.showVerity = false
            state.catcha.imgurl = ''
            state.catcha.miniimgurl = ''
            //发送短信验证码
            sendMessage();
            // this.loginForm.distance = left
            // this.toLogin()
          }, 1000)
        } else {
          dialogopen.value?.handleFail(res.msg)
          setTimeout(() => {
            getImageVerifyCode()
          }, 500)
        }
      }).catch(() => {
          dialogopen.value?.handleFail()
          setTimeout(() => {
            getImageVerifyCode()
          }, 500)
      })      
    }
    //刷新
    const onRefresh = () => {
      state.catcha.imgurl = ''
      state.catcha.miniimgurl = ''
      getImageVerifyCode()   
    }
    //失败
    const onFail = () => {
      console.log('fail')
    }
    
    //发送短信验证码
    const sendMessage = () => {
      const param = {
        phone:decodeURIComponent(state.datalist.contactsPhone),
        verifyKey:decodeURIComponent(state.verifyKey)
      }
      sendMessageApi(param).then(res => {
        if (res && res.code == 200) {
          state.datalist.smsUuid = res.data;
          //倒计时
          if (!timer.value) {
            count.value = TIME_COUNT.value;
            showcount.value = false;
            timer.value = setInterval(() => {
              if (count.value > 0 && count.value <= TIME_COUNT.value) {
                count.value--;
                console.log("count----", count);
              } else {
                showcount.value = true;
                clearInterval(timer.value);
                timer.value = null;
              }
            }, 1000);
          }
        }else{
          Toast(res.msg||'发送短信失败，请稍后重试！')
          // Notify({ 
          //   message: res.msg||'发送短信失败，请稍后重试！'
          // });
        }
      }).catch((error) => {
        Toast(error.message||'发送短信失败，请稍后重试！')
        // Notify({ 
        //   message:  error.message||'发送短信失败，请稍后重试！'
        // });       
      }) 

    }

    
    //上一步
    const applyPrev = () => {
      if (state.step == 3 && state.channelCode == 'ZFB') {
        state.step = state.step - 2;
        return;
      }
      state.step = state.step-1;
    }
    //下一步
    const applyNext = () => {
      if(state.payStatus == 3){
        if(state.step == 4){
          Toast('资料已审核通过，无需再次提交！');
          return false;
        }else{
          if (state.step == 1 && state.channelCode == 'ZFB') { // 支付宝渠道不需要
            state.step = state.step+2;
            return
          }
          state.step = state.step+1;
          return
        }
      }
      setLocal('applyData'+state.channelCode, state.datalist, 360);
      if(state.step == 4){
        // if(!state.datalist.zfbAccount){
        //   Toast('请填写支付宝账号！');
        //   return false;
        // }
        // if(!state.datalist.contactsEmail){
        //   Toast('请填写联系邮箱！');
        //   return false;
        // }
        if (!(/^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/.test(state.datalist.contactsEmail))) {
          Toast("请输入合法联系邮箱！")
          return false
        }
        // if(!state.datalist.contactsPhone){
        //   Toast('请填写联系手机号！');
        //   return false;
        // }
        if (!(/^(\((\+)?86\)|((\+)?86)?)0?1[3456789]\d{9}$/.test(state.datalist.contactsPhone))) {
          Toast('请填写合法联系手机号！');
          return false;
        }
        if(!state.datalist.smsUuid){
          Toast('请填写正确的验证码！');
          return false;
        }
        if(!state.datalist.verificationCode){
          Toast('请填写短信验证码！');
          return false;
        }
        //提交保存
        applySubmit();
        
      }else{
        if(state.step == 1){
          if(!state.datalist.usccImg){
            Toast('请上传营业执照！');
            return false;
          }
          if(!state.datalist.businessLicence || !state.datalist.businessLicenceNo){
            Toast('营业执照识别有误，请重新上传，确保拍照清晰！');
            return false;
          }
          if(!state.datalist.legalFrontImg){
            Toast('请上传法人身份证正面照！');
            return false;
          }
          if(!state.datalist.legalName || !state.datalist.cardNo){
            Toast('法人身份证正面照识别有误，请重新上传，确保拍照清晰！');
            return false;
          }
          if(!state.datalist.legalBackImg){
            Toast('请上传法人身份证反面照！');
            return false;
          }
          if(!state.datalist.cardStartDate || !state.datalist.cardStartEnd){
            Toast('法人身份证反面照识别有误，请重新上传，确保拍照清晰！');
            return false;
          }

        }
        if(state.step == 2){
          if(state.datalist.settleAccType == '0'){
            if(!state.datalist.accountOpenImg){
              Toast('请上传法开户许可证！');
              return false;
            }
            // if(!state.datalist.bankMobile){
            //   Toast('请填写开户预留手机号！');
            //   return false;
            // }
            if (!(/^(\((\+)?86\)|((\+)?86)?)0?1[3456789]\d{9}$/.test(state.datalist.bankMobile))) {
              Toast('请填写合法开户预留手机号！');
              return false;
            }
            if(!state.datalist.balanceAccName){
              Toast('请填写结算户名！');
              return false;
            }
            if(!state.datalist.balanceAccCardno){
              Toast('请填写结算账号！');
              return false;
            }
            if(!state.datalist.openaccAddrCode){
              Toast('请选择开户行地址！');
              return false;
            }
            if(!state.datalist.openaccId){
              Toast('请选择开户银行支行！');
              return false;
            }
          }
          if(state.datalist.settleAccType == '1'){
            if(!state.datalist.settleCardFrontImg1){
              Toast('请上传结算银行卡正面照！');
              return false;
            }
            if(!state.datalist.settleCardBackImg1){
              Toast('请上传结算银行卡反面照！');
              return false;
            }
            // if(!state.datalist.bankMobile1){
            //   Toast('请填写开户预留手机号！');
            //   return false;
            // }
            if (!(/^(\((\+)?86\)|((\+)?86)?)0?1[3456789]\d{9}$/.test(state.datalist.bankMobile1))) {
              Toast('请填写合法开户预留手机号！');
              return false;
            }
            if(!state.datalist.balanceAccName1){
              Toast('请填写结算户名！');
              return false;
            }
            if(!state.datalist.balanceAccCardno1){
              Toast('请填写结算账号！');
              return false;
            }
            if(!state.datalist.openaccAddrCode1){
              Toast('请选择开户行地址！');
              return false;
            }
            if(!state.datalist.openaccId1){
              Toast('请选择开户银行支行！');
              return false;
            }
          }
          if(state.datalist.settleAccType == '2'){
            if(!state.datalist.settleCardFrontImg2){
              Toast('请上传结算银行卡正面照！');
              return false;
            }
            if(!state.datalist.settleCardBackImg2){
              Toast('请上传结算银行卡反面照！');
              return false;
            }
            if(!state.datalist.unincorporateCardFrontImg2){
              Toast('请上传非法人身份证正面照！');
              return false;
            }
             if(!state.datalist.authSettleImg){
             Toast('请上传授权书！');
              return false;
            }
            if(!state.datalist.balanceAccName2 || !state.datalist.unincorporatedName2){
              Toast('非法人身份证正面照识别有误，请重新上传，确保拍照清晰！');
              return false;
            }
            if(!state.datalist.unincorporateCardBackImg2){
              Toast('请上传法人身份证反面照！');
              return false;
            }
            // if(!state.datalist.bankMobile2){
            //   Toast('请填写开户预留手机号！');
            //   return false;
            // }
            if (!(/^(\((\+)?86\)|((\+)?86)?)0?1[3456789]\d{9}$/.test(state.datalist.bankMobile2))) {
              Toast('请填写合法开户预留手机号！');
              return false;
            }
            if(!state.datalist.balanceAccName2){
              Toast('请填写结算户名！');
              return false;
            }
            if(!state.datalist.balanceAccCardno2){
              Toast('请填写结算账号！');
              return false;
            }
            if(!state.datalist.openaccAddrCode2){
              Toast('请选择开户行地址！');
              return false;
            }
            if(!state.datalist.openaccId2){
              Toast('请选择开户银行支行！');
              return false;
            }
          }
        }
        if(state.step == 3){
          if(!state.datalist.headStoreImg){
            Toast('请上传门店门头照！');
            return false;
          }
          if(!state.datalist.inStoreImg){
            Toast('请上传门店内景照！');
            return false;
          }
          if(!state.datalist.cashStoreImg && (state.channelCode == 'YS' || state.channelCode == 'LKL')){
            Toast('请上传门店收银台照！');
            return false;
          }
        }
        if (state.step == 1 && state.channelCode == 'ZFB') { // 支付宝渠道不需要
          state.step = state.step+2;
          return
        }
        state.step = state.step+1;
      }
     
    }
    //提交保存
    const applySubmit = () => {
      let balanceAccType = '21';
      let codeLegalPersonAcc = '1';
      let balanceAccCardno = state.datalist.balanceAccCardno;
      let balanceAccName = state.datalist.balanceAccName;
      let bankMobile = state.datalist.bankMobile;
      let openaccAddrCode = state.datalist.openaccAddrCodeId;
      let openaccId = state.datalist.openaccId;
      let settleCardBackImg =null;
      let settleCardFrontImg =null;
      if(state.datalist.settleAccType == '1'){
        balanceAccType = '11';
        codeLegalPersonAcc = '1';
        balanceAccCardno = state.datalist.balanceAccCardno1;
        balanceAccName = state.datalist.balanceAccName1;
        bankMobile = state.datalist.bankMobile1;
        openaccAddrCode = state.datalist.openaccAddrCodeId1;
        openaccId = state.datalist.openaccId1;
        settleCardBackImg = state.datalist.settleCardBackImg1;
        settleCardFrontImg = state.datalist.settleCardFrontImg1;
      }else if(state.datalist.settleAccType == '2'){
        balanceAccType = '11';
        codeLegalPersonAcc = '0';
        balanceAccCardno = state.datalist.balanceAccCardno2;
        balanceAccName = state.datalist.balanceAccName2;
        bankMobile = state.datalist.bankMobile2;
        openaccAddrCode = state.datalist.openaccAddrCodeId2;
        openaccId = state.datalist.openaccId2;
        settleCardBackImg = state.datalist.settleCardBackImg2;
        settleCardFrontImg = state.datalist.settleCardFrontImg2;
      }
      const param = {
        "isWxAuth": state.datalist.isWxAuth,
        "channelCode": state.channelCode,
        "merAgreementInfo": {  //协议信息
          "zfbAccount": state.datalist.zfbAccount,
          "contactsEmail": state.datalist.contactsEmail,
          "contactsPhone": state.datalist.contactsPhone,
          "smsUuid": state.datalist.smsUuid,
          "verificationCode": state.datalist.verificationCode
        },
        "merBaseInfo": {  //基本信息
          "merType": Number(state.datalist.merType),
          "usccImg": state.datalist.usccImg,  //营业执照照片
          "businessLicence": state.datalist.businessLicence, //'测试门店',//state.datalist.businessLicence,
          "businessLicenceEdate": state.datalist.businessLicenceEdate,
          "businessLicenceNo": state.datalist.businessLicenceNo,
          "businessLicenceSdate": state.datalist.businessLicenceSdate,
          "registeredAddress": state.datalist.registeredAddress,
          "merAddress": state.datalist.merAddress,
          "merAddressDetail": state.datalist.merAddressDetail,

          "legalFrontImg": state.datalist.legalFrontImg,  //法人身份证正面照
          "legalBackImg": state.datalist.legalBackImg,
          "cardNo": state.datalist.cardNo,  
          "cardStartDate": state.datalist.cardStartDate,
          "cardStartEnd": state.datalist.cardStartEnd,
          "crpAddr": state.datalist.crpAddr,
          "crpGender": state.datalist.crpGender,
          "legalName": state.datalist.legalName,
          "legalPersonPhone": state.datalist.contactsPhone,
        },
        "merSettleInfo": {  //结算账户
          "balanceAccType": balanceAccType,
          "codeLegalPersonAcc": codeLegalPersonAcc,
          "accountOpenImg": state.datalist.accountOpenImg,  //开户许可证
          "balanceAccCardno": balanceAccCardno,   //结算账户卡号
          "balanceAccName": balanceAccName,
          "bankMobile": bankMobile,
          "openaccAddrCode": openaccAddrCode,
          "openaccId": openaccId,
          "settleCardBackImg": settleCardBackImg,
          "settleCardFrontImg": settleCardFrontImg,
          "unincorporateCardBackImg": state.datalist.unincorporateCardBackImg2,
          "unincorporateCardFrontImg": state.datalist.unincorporateCardFrontImg2,
          "authSettleImg":state.datalist.authSettleImg,//授权书
          "unincorporatedId": state.datalist.unincorporatedId2,
          "unincorporatedName": state.datalist.unincorporatedName2,
          "lowestStlAmt": 1,  //结算起始金额，默认1[YS必须]
          "wxFee": 0.3,
          "ysfFee1": 0.6,
          "ysfFee2": 0.3,
          "zfbAccount": null,
          "zfbFee": 0.3,
        },
        "merStoreInfo": {   //门店照片
          "cashStoreImg": state.datalist.cashStoreImg,
          "headStoreImg": state.datalist.headStoreImg,
          "inStoreImg": state.datalist.inStoreImg
        }
      }      
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      applySubmitApi(param).then(res => {
        if (res.code == 200) {
          removeLocal('applyData'+state.channelCode);
          state.merId = res.data;
          store.dispatch('QueryUserIsBind') 
          Toast(res.msg||'提交成功');
          state.showFace = !state.showFace;            

          wechatConfig(
            this,
            "天美支付",
            "邀请您进行法人认证,用于天美支付申请开通",
            "face?merid="+state.merId+"&channelcode="+state.channelCode+"&type=1&time="+Date.now(),
          ).then((res) => {
            //需要获取方法执行结果，可以加.then这一段；
            //不需要可以不加
          });
        }else{
          // Notify({ 
          //   lockScroll:true,
          //   message: res.msg||'提交资料失败！'
          // });
          Toast(res.msg||'提交资料失败！');
        }
      })
    }
    //页面销毁事件
    onBeforeUnmount(async () => {
      if(state.payStatus != 3){
        setLocal('applyData'+state.channelCode, state.datalist, 360);
      }
    })
    return {
      ...toRefs(state),
      applyNext,
      applyPrev,
      onConfirmAddr,
      bankPop,
      onConfirmBank,
      hideBank,
      uploadImg,
      authFace,
      SendVerif,
      dialogopen,
      verifysuccess,
      hideVerify,
      getImageVerifyCode,
      onSuccess,
      onRefresh,
      onFail,
      sendMessage,
      showcount,
      timer,
      TIME_COUNT,
      count,
      applySubmit,
      previewPic,
      downFile
    }
  }

}
</script>

<style lang="less" scoped >
  @import '../assets/style/mixin';
  /* div{
    box-sizing: content-box;
  } */
.container {
  background-color: #ffffff;
  font-size: 12px;
}

/* 进度条 */
.progress-bar {
  height: 82.5px;
  padding: 15px 0;
  background-color: #efefef;
  position: relative;
}

.step-box {
  justify-content: space-around;
  background-color: #ffffff;
}

.step-item {
  width: 25%;
  height: 82.5px;
  justify-content: flex-start;
}

.step-bar {
  width: 100%;
  position: relative;
}

.step-line {
  position: absolute;
  left: -50%;
  top: 26px;
  width: 100%;
  border-top: 1px solid #999999;
  margin-top: 3px;
}

.circle-box {
  background-color: #ffffff;
  width: 39px;
  position: relative;
  z-index: 1;
}

.circle {
  width: 17px;
  height: 17px;
  background: #EFEFEF;
  border: 2px solid #EFEFEF;
  border-radius: 50%;
  margin: 18px 0 15px;
}

.step-item.active .circle {
  background: @mainc;
  border: 2px solid @mainc;
}

.step-item.curr .circle {
  background: rgba(250, 52, 116, 0.1);
  border: 2px solid @mainc;
}

.step-item.active .step-line,
.step-item.curr .step-line {
  border-top: 1px solid @mainc;
}

.step-text {
  font-size: 12px;
  font-weight: 400;
  color: #999999;
}

.step-item.active .step-text,
.step-item.curr .step-text {
  color: @mainc;
}

/* 步骤内容 */
.step-container {
  padding: 15px;
}

.step-title {
  font-size: 11px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 20px;
}

.form-row {
  margin-bottom: 24px;
}

/* 搜索 单选框 */

.form-row .radio {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 69px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}

/* vant radio自定义样式 */
/deep/.radio-group {
  .van-radio__label{
    font-size: 15px;
  }
  .van-radio[aria-checked='true'] .van-radio__label{
    color: @mainc!important;
  }
  /* .settle-radio{
    margin-right: 0;
  } */
  .settle-radio .van-radio__label{
    font-size: 12px;
  }
  .van-icon{
    width: 14px;
    height: 14px;
    /* line-height: 18px; */
    font-size:14px;
    border: 1px solid @mainc;
    border-radius: 100%;
    background: none !important;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: content-box;
  }
  
  /*复选框选中后内部样式*/
  .van-radio__icon--checked .van-icon-success::before {
    width: 8px;
    height: 8px;
    background: @mainc;
    border-radius: 8px;
    content: '';
    /* transform: translate(0, -30%) scale(1);
    -webkit-transform: translate(0, -30%) scale(1); */
  }
}

.form-title {
  font-size: 11px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 30px;
}

.camera-box {
  width: 145px;
  height: 95px;
  background: #F4F4F4;
  margin-left: 1px;
  position: relative;
  border: 1px dashed @mainc;
}

.valid-failed .camera-box{
  -webkit-animation: free_download1 0.8s linear alternate infinite;
    animation: free_download1 0.8s linear alternate infinite;
}
@-webkit-keyframes free_download1{
  0%{border: 1px dashed #F4F4F4;}
  100%{border: 1px solid #FA3474;}
}
@keyframes free_download1{
  0%{border: 1px dashed #F4F4F4;}
  100%{border: 1px solid #FA3474;}
}

.material-img {
  width: 105px;
  height: 67px;
  position: relative;
}
.img-load-box{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.img-load-box::before{
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, .6);
}
.camera-img {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  width: 42px;
  height: 42px;
}

.material-title {
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  margin-top: 11px;
}

.eg-box {
  margin-left: 28.5px;
}

.eg-text {
  font-size: 11px;
  font-weight: bold;
  color: #999999;
  margin-bottom: 6.5px;
}

.eg-img {
  width: 105px;
  height: 75px;
}

.id-reverse {
  margin-left: 42.5px;
}


.opt-box {
  height: 74px;
}
.opt-box .opt{
  position: fixed;
  bottom: 0px;
  left: 15px;
  right: 15px;   
  background-color: #ffffff;
  padding: 15px 0; 
}
.opt-box .opt.opt-2{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.apply-btn {
  width: 100%;
  height: 44px;
  background: @mainc;
  border-radius: 3px;
  font-size: 15px;
  font-weight: 400;
  color: #FFFFFF;
}

.first .next-btn {
  width: 345px;
  height: 44px;
  background: @mainc;
  border-radius: 3px;
  font-size: 15px;
  font-weight: 400;
  color: #FFFFFF;
}

/* 第二步 */
.step-two .radio-group {
  justify-content: space-between;
}

.step-two .radio {
  margin-right: 0;
  font-size: 12px;
}

.form-controls {
  position: relative;
}

.form-controls .input {
  border-bottom: solid 1px #e5e5e5;
  font-size: 14px;
  line-height: 40px;
  height: 40px;
  padding: 0;
  width: 100%;
}
.bb1{
  border-bottom: solid 1px #e5e5e5;
}
.msg-code{
  width: 64%!important;
  border-bottom: 0!important;
}

.form-in {
  margin-bottom: 15px;
}

.form-in .form-title {
  margin-bottom: 5px;
}
.required{
  color: red;
}

.prev-btn {
  width: 40%;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid @mainc;
  border-radius: 3px;
  font-size: 15px;
  font-weight: 400;
  color: @mainc;
  box-sizing: border-box;
}

.next-btn {
  width: 55%;
  height: 44px;
  background: @mainc;
  border-radius: 3px;
  font-size: 15px;
  font-weight: 400;
  color: #FFFFFF;
}

.check-tips {
  font-size: 11px;
  font-weight: 400;
  color: @mainc;
  margin-left: 11.5px;
  align-items: center;
}

.hand-img {
  width: 12.5px;
  height: 10.5px;
  margin-right: 4px;
}

/* 第四步 */
.tips {
  padding: 10px;
  background: #EEEEEE;
  border-radius: 3px;
  font-size: 11px;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
}

.xieyi-in {
  color: @mainc!important;
  font-size: 15px;
}

.newBindBut {
  width: 95px !important;
  height: 24px;
  background: @mainc;
  border-radius: 3px !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #FEFEFE;
  padding: 0 !important;
  margin: 0;
  position: absolute;
  right: 0;
  bottom: 4px;
  z-index: 10;
}

.code-img {
  width: 63px;
  height: 21.5px;
  position: absolute;
  right: 0;
  bottom: 9px;
}

.getcode {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 15px;
  font-weight: 400;
  line-height: 40px;
  color: @mainc;
}


/* 促销弹出框 */

.PullUp {
  background-color: #fff;
  padding: 8px 15px 30px;
}

.PullUp .title {
  color: #646464;
  text-align: center;
  padding: 10px 0 20px;
  justify-content: center;
  position: relative;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
}

.PullUp .title .close-btn {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 15px;
  font-size: 24px;
  top: 15px;
}

.PullUp .PullUpContent {
  min-height: 270px;
  max-height: 430px;
  overflow-y: scroll;
}
.PullUp .explain{
  font-size: 15px;
  font-weight: 400;
  color: #333333;  
  line-height: 24px;
}
.PullUp .faren{
  color: @mainc;
}

.PullUp .auth-label{
  margin-top: 23px;
  margin-bottom: 15px;
}
.PullUp .auth-type{
  width: 345px;
  height: 38px;
  background: @mainc;
  border-radius: 2px;
  font-size: 15px;
  font-weight: 400;
  color: #FFFFFF;
}
.PullUp .p-tips{
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  margin-top: 9px;
}
</style>