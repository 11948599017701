
import request from "@/utils/request";


//ORC图片智能识别
export function orcImgApi(url) {
  return request({
    url,
    method: 'post'
  })
}

// 查询基础地区列表
export function listRegion() {
  return request({
    url: '/base/common/list',
    method: 'get',
  })
}

// 查询银行列表
export function listBank(cityCode1, cityCode2) {
  let cityCode = [cityCode1];
  if (cityCode2) {
    cityCode.push(cityCode2);
  }
  return request({
    url: '/base/common/list/bank/' + cityCode,
    method: 'get',
  });
}

//生成滑动验证码
export function getKaptchaImg(data) {
  return request({
    url: '/tmCaptchaImage',
    method: 'post', 
    data
  })
}

//滑动验证码校验
export function checkKaptchaImg(data) {
  return request({
    url: '/tmVerifyCode',
    method: 'post', 
    data
  })
}

//发送短信验证码
export function sendMessageApi(data) {
  return request({
    url: '/send/smsByCode',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    params:data
  })
}

//提交商户资料
export function applySubmitApi(data) {
  return request({
    url: '/wxapp/mer/submit',
    method: 'post', 
    data
  })
}

//查询商户提交的资料
export function getMerApplyInfo(channelCode) {
  return request({
    url: '/wxapp/mer/submitInfo?channelCode='+channelCode,
    method: 'get'
  })
}

// 查询产品开通状态
export function QueryProductAuditStatus(type) {
  return request({
    url: `/wxapp/mer/auditStatus?channelCode=` + type,
    method: 'get'
  })
}


// 分页查询开户行
export function getBankListApi(data) {
  return request({
    url: `/base/common/list/bankByCityCode`,
    method: 'get',
    params:data
  })
}